<template>
  <div>
    <MealMenu></MealMenu>
  </div>
</template>

<style lang="scss">
  // Module styles
  @import "assets/sass/style";
</style>

<script>
import MealMenu from "./views/MealMenu";


export default {
  components: {
    MealMenu
  }
};
</script>