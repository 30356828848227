<template>
  <div class="public-mealmenu">
    <div class="week-mealmenu-heading-flex">
      <div class="week-mealmenu-heading">
        <h1>{{ $t('mealmenu.title.lunch_menu') }}</h1>
      </div>
      <div class="week-mealmenu-heading-dates">
        <div>{{ weekStart }}</div>
        <div>-</div>
        <div>{{ weekEnd }}</div>
      </div>
    </div>

    <article v-if="mealMenu.meal_menu && mealMenu.meal_menu.length > 0"> 
      <section class="day" v-for="(days, index) in mealMenu.meal_menu" :key="index">
        <div class="day-content">
          <div class="day-info">
            <h3 class="capitalize">{{ days.date | moment("dddd") }}</h3>
            <span>/</span>
            <h3>{{ days.date | moment("DD.MM.YYYY") }}</h3>
          </div>

          <div class="meals" v-if="days.types && days.types.length > 0">
            <div v-for="(day, index) in days.types" :key="index">

                <div class="meal-info">
                  <h3 style="">{{ day.name }}:</h3>
                  <h4 style="">{{ $t('mealmenu.title.alergens') }}</h4>
                </div>

                <ol v-if="day.meals && day.meals.length > 0">
                  <li v-for="(meal, index) in day.meals" :key="index">
                    <em>{{ meal.meal_menu_number }}</em>
                    <span>{{ meal.name }}</span>
                    <span v-if="meal.allergens_number_string != ''">{{ meal.allergens_number_string }}</span>
                    <span v-else class="text-muted">-</span>
                  </li>
                </ol>
                <div v-else class="text-muted">
                  {{ $t('mealmenu.title.no_set_meal') }}
                </div>
            </div>

          </div>
          <div v-else class="text-muted">
            -
          </div>
        </div>
      </section>
    </article>

    <div class="footer-info" v-if="mealMenu && mealMenu.category && mealMenu.category.name != ''">
    <p>{{ mealMenu.category.name }}</p>
    <p>{{ $t('mealmenu.title.valid_to') }} <span>{{ dateStart }}</span></p>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import i18n from "@/plugins/vue-i18n";
import moment from "@/plugins/moment";

export default {
  metaInfo() {
    return { title: i18n.t("meta.week_menu") };
  },
  data() {
    return {
      dateStart: moment(moment()).format("DD.MM.YYYY HH:mm"),
      actualWeek: moment(moment()).format("W"),
      actualYear: moment(moment()).format("Y"),
      weekStart: moment(moment(this.dateStart).weekday(0)).format("DD.MM.YYYY"),
      weekEnd: moment(moment(this.dateStart).weekday(4)).format("DD.MM.YYYY"),
      categoryDetail: null,
      mealMenu: null,
      categoryName: "Žáci a studenti",
    };
  },
  mounted() {
    this.getMealMenu();
    this.getCategoryDetail();
  },
  methods: {
    getMealMenu() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "meal-menu/week?week_number=" +
          this.actualWeek +
          "&year=" +
          this.actualYear +
          "&diner_category_id=" +
          this.$route.params.id
      ).then((response) => {
        this.mealMenu = response.data;
        this.loading = false;
      });
    },
  },
};
</script>